<script>
import TransitionToggle from "../transitions/toggle.vue";
import _ from "lodash";

export default {
    name: "autocomplete",

    components: {TransitionToggle},

    props: {
        modelValue: {
            type: [String, Number]
        },

        autocomplete: {
            type: String
        },

        field: {
            type: String,
            default: 'name'
        }
    },

    emits: ['update:modelValue'],

    computed: {
        searchDebounce: {
            get: function () {
                return this.modelValue;
            },
            set: function (value) {
                this.tempSearch = value
                _.debounce( (value) => {
                    if (this.tempSearch === value) {
                        this.search = value;
                        // this.$emit('update:modelValue', this.search);
                        this.fetch();
                    }
                }, 700)(value);
            },
        },

        loading: function () {
            return this.$store.getters[this.autocomplete + '/autocompleteLoading'];
        },

        list: function () {
            return this.$store.getters[this.autocomplete + '/autocompleteList'];
        }
    },

    data: function () {
        return {
            tempSearch: null,
            search: '',
            searchFocused: false,
            hoveredItem: null
        };
    },

    methods: {
        fetch: function () {
            if (this.search) {
                this.$store.dispatch(this.autocomplete + '/autocomplete', {text: this.search});
            } else {
                this.$store.dispatch(this.autocomplete + '/clearAutocomplete');
                this.search = '';
                this.$emit('update:modelValue', this.search);
            }
        },

        select: function (item) {
            this.search = item[this.field];
            this.$emit('update:modelValue', this.search);
            this.$store.dispatch(this.autocomplete + '/clearAutocomplete');
        },

        closeList: function () {
            this.searchFocused =  false;
            this.$store.dispatch(this.autocomplete + '/clearAutocomplete');
        },

        emit: function () {
            this.$emit('update:modelValue', this.search);
        }
    },
}
</script>

<template>
    <div class="position-relative">
        <b-input-group>
            <b-input-group-text class="bg-white border-right-0" :class="{focused: searchFocused}">
                <i class="fas fa-search"></i>
            </b-input-group-text>
            <input class="form-control border-left-0"
                   type="search"
                   v-model="searchDebounce"
                   :placeholder="$t('base.search').ucFirst()"
                   @focusin="searchFocused = true"
                   @blur="closeList"
                   @keyup.enter="emit"
            />
        </b-input-group>
        <transition-toggle>
            <div v-if="loading || list?.length" class="autocomplete-list w-100 position-absolute bg-white border rounded overflow-hidden">
                <b-overlay :show="loading" wrap-tag="div" overlay-tag="div">
                    <ul class="list-unstyled">
                        <li class="cursor-pointer ps-5 pe-2 py-1 font-size-12" @mousedown.stop="select(item)" v-for="(item, index) in list" :key="index">{{ item[field] }}</li>
                    </ul>
                </b-overlay>
            </div>
        </transition-toggle>
    </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/bootstrap.scss" as colors;

.autocomplete-list {
    margin-top: 2px;
    z-index: 1;
}
li{
    &:hover {
        background-color: lighten(colors.$primary, 25%) !important;
    }
}
</style>
